import { ASSIGNED_RESOURCES_ID } from '../components/SidebarWrapperTwoLevels/constants';
import { VIDEO } from '../components/assignments/constants';

/**
 * Parent IDS
 */
export const FYM_APP_ID = 10000;
export const QB_APP_ID = 33;

/**
 * Master subject IDS
 */

export const AP_DRAWING = 3;
export const AP_2D_ART_AND_DESIGN = 4;
export const AP_3D_ART_AND_DESIGN = 5;
export const AP_SEMINAR = 94;
export const AP_RESEARCH = 95;
export const AP_COMPUTER_SCIENCE_PRINCIPLES = 103;
export const AP_AFRICAN_AMERICAN_STUDIES = 116;
export const AP_PRE_CALCULUS = 117;

export const AP_CSA = 8;
export const AP_ART_HISTORY = 2;
export const AP_BIOLOGY = 6;
export const AP_CHEMISTRY = 7;
export const AP_COMP_GO_PO = 21;
export const AP_ENVIRONMENTAL_SCI = 14;
export const AP_ENGLISH_LIT = 13;
export const AP_ENGLISH_LANG = 12;
export const AP_EUROPEAN_HIS = 15;
export const AP_HUMAN_GEOGRAPHY = 18;
export const AP_MUSIC_THEORY = 27;
export const AP_PHYSICS_1 = 92;
export const AP_PHYSICS_2 = 93;
export const AP_PHYSICS_C_EM = 78;
export const AP_PHYSICS_C_MECHANIC = 29;
export const AP_PHYSCOLOGY = 30;
export const AP_SPANISH_LIT = 32;
export const AP_STATISTIC = 33;
export const AP_US_GOV_POL = 20;
export const AP_US_HISTORY = 1;
export const AP_WORLD_HISTORY = 34;
export const AP_LATIN = 23;
export const AP_CHINESE = 87;
export const AP_JAPANESE = 88;
export const AP_FRENCH = 16;
export const AP_GERMAN = 19;
export const AP_SPANISH = 31;
export const AP_ITALIAN = 86;

export const AP_CALCULUS_AB = 25;
export const AP_CALCULUS_BC = 26;

export const AP_MACROECONOMICS = 10;
export const AP_MICROECONOMICS = 11;

export const PREAP_ALGEBRA = 104;
export const PREAP_BIOLOGY = 105;
export const PREAP_ENG1 = 106;
export const PREAP_WHG = 107;
export const PREAP_DANCE = 108;
export const PREAP_THEATRE = 109;
export const PREAP_VISUAL_ARTS = 110;
export const PREAP_MUSIC = 111;
export const PREAP_ENG2 = 112;
export const PREAP_CHEMISTRY = 113;
export const PREAP_GEOMETRY = 114;
export const PREAP_ALGEBRA2 = 115;

export const PREAP_SUBJECTS = [
  PREAP_ALGEBRA,
  PREAP_BIOLOGY,
  PREAP_ENG1,
  PREAP_WHG,
  PREAP_DANCE,
  PREAP_THEATRE,
  PREAP_VISUAL_ARTS,
  PREAP_MUSIC,
  PREAP_ENG2,
  PREAP_CHEMISTRY,
  PREAP_GEOMETRY,
  PREAP_ALGEBRA2,
];

export const STEM_COURSE_INT_IDS = [
  PREAP_ALGEBRA,
  PREAP_BIOLOGY,
  PREAP_WHG,
  PREAP_CHEMISTRY,
  PREAP_GEOMETRY,
  PREAP_ALGEBRA2,
];

export const GRADE_9_SUBJECTS = [
  PREAP_ALGEBRA,
  PREAP_BIOLOGY,
  PREAP_WHG,
  PREAP_ENG1,
];

export const GRADE_10_SUBJECTS = [
  PREAP_CHEMISTRY,
  PREAP_ENG2,
  PREAP_GEOMETRY,
  PREAP_ALGEBRA2,
];

export const GRADE_ARTS_SUBJECTS = [
  PREAP_DANCE,
  PREAP_THEATRE,
  PREAP_VISUAL_ARTS,
  PREAP_MUSIC,
];

export const APPortfolioCourses = [
  AP_DRAWING,
  AP_2D_ART_AND_DESIGN,
  AP_3D_ART_AND_DESIGN,
  AP_SEMINAR,
  AP_RESEARCH,
  AP_COMPUTER_SCIENCE_PRINCIPLES,
];

/*
 * Subjects without Student FRQ Practice Assignments
 * */
export const subjectsWithNoPracticeAssignments = APPortfolioCourses;

export const preapArtSubjectIds = ['108', '111', '109', '110'];

export const subjectsThatUseBluebook = [
  1, 2, 6, 7, 8, 10, 11, 12, 13, 14, 15, 18, 20, 21, 23, 25, 26, 29, 30, 33, 34,
  78, 92, 93, 94, 103, 116, 117,
];

export const NON_BB_COURSES = [16, 19, 27, 31, 32, 86, 87, 88]; // courses that don't use bluebook

/**
 * Subjects without Ap Daily todo cards for video imports by unit
 */
//export const subjectsWithNoStudentsApDailyTodoCards = APPortfolioCourses;

export const subjectsWithNoStudentsApDailyTodoCards = APPortfolioCourses;

export const MathematicsSubjects = [
  AP_CALCULUS_AB,
  AP_CALCULUS_BC,
  AP_STATISTIC,
  AP_CHEMISTRY,
  AP_BIOLOGY,
  AP_PHYSICS_1,
  AP_PHYSICS_2,
  AP_PHYSICS_C_MECHANIC,
  AP_PHYSICS_C_EM,
  AP_PRE_CALCULUS,
];

export const PPC = 'PPC';
export const QB = 'QB';
export const MQ = 'MQ';
export const PE = 'PE';
export const SPP = 'SPP';
export const REPORTS = 'reports';
export const LABELS = 'labels';
export const MY_ASSIGNMENTS = 'my-assignments';

const RESTRICTIONS_BY_SUBJECT = {
  [VIDEO]: [AP_AFRICAN_AMERICAN_STUDIES],
  [SPP]: [AP_AFRICAN_AMERICAN_STUDIES],
  [PPC]: [
    AP_SEMINAR,
    AP_RESEARCH,
    AP_COMPUTER_SCIENCE_PRINCIPLES,
    AP_2D_ART_AND_DESIGN,
    AP_3D_ART_AND_DESIGN,
    AP_DRAWING,
    AP_AFRICAN_AMERICAN_STUDIES,
  ],
  [QB]: [
    AP_RESEARCH,
    AP_2D_ART_AND_DESIGN,
    AP_3D_ART_AND_DESIGN,
    AP_DRAWING,
    AP_AFRICAN_AMERICAN_STUDIES,
  ],
  [PE]: [
    AP_SEMINAR,
    AP_RESEARCH,
    AP_2D_ART_AND_DESIGN,
    AP_3D_ART_AND_DESIGN,
    AP_DRAWING,
    AP_AFRICAN_AMERICAN_STUDIES,
  ],
  [MQ]: [
    AP_RESEARCH,
    AP_2D_ART_AND_DESIGN,
    AP_3D_ART_AND_DESIGN,
    AP_DRAWING,
    AP_AFRICAN_AMERICAN_STUDIES,
  ],
  [REPORTS]: [AP_AFRICAN_AMERICAN_STUDIES],
  [ASSIGNED_RESOURCES_ID]: [AP_AFRICAN_AMERICAN_STUDIES],
  [LABELS]: [AP_AFRICAN_AMERICAN_STUDIES],
  [MY_ASSIGNMENTS]: [AP_AFRICAN_AMERICAN_STUDIES],
};

export const isSubjectHasResource = (subjectId, resource) =>
  !(
    RESTRICTIONS_BY_SUBJECT[resource] &&
    Array.isArray(RESTRICTIONS_BY_SUBJECT[resource]) &&
    RESTRICTIONS_BY_SUBJECT[resource].includes(+subjectId)
  );

export const TASK_MODEL_SUBJECTS_IDS = [
  AP_FRENCH,
  AP_GERMAN,
  AP_SPANISH,
  AP_ITALIAN,
  AP_CHINESE,
  AP_JAPANESE,
];

export const FOREIGN_LANGUAGE_SUBJECTS_IDS = [
  AP_FRENCH,
  AP_GERMAN,
  AP_SPANISH,
  AP_ITALIAN,
  AP_CHINESE,
  AP_JAPANESE,
  AP_LATIN,
  AP_SPANISH_LIT,
];

export const WORLD_LANGUAGE_SUBJECTS_IDS = [
  ...FOREIGN_LANGUAGE_SUBJECTS_IDS,
  AP_ENGLISH_LANG,
  AP_ENGLISH_LIT,
];

export const DRAWING_SUBJECTS = [
  AP_DRAWING,
  AP_2D_ART_AND_DESIGN,
  AP_3D_ART_AND_DESIGN,
];

export const HIERARCHY_DEPENDEND_FILTERS = {
  [AP_US_HISTORY]: ['Unit and Topic'],
  [AP_ART_HISTORY]: ['Unit and Topic'],
  [AP_BIOLOGY]: ['Unit and Topic'],
  [AP_CHEMISTRY]: ['Unit and Topic'],
  [AP_CSA]: ['Unit and Topic'],
  [AP_MACROECONOMICS]: ['Unit and Topic'],
  [AP_MICROECONOMICS]: ['Unit and Topic'],
  [AP_ENGLISH_LANG]: ['Unit'],
  [AP_ENGLISH_LIT]: ['Unit'],
  [AP_ENVIRONMENTAL_SCI]: ['Unit and Topic'],
  [AP_EUROPEAN_HIS]: ['Unit and Topic'],
  [AP_HUMAN_GEOGRAPHY]: ['Unit and Topic'],
  [AP_US_GOV_POL]: ['Unit and Topic'],
  [AP_COMP_GO_PO]: ['Unit and Topic'],
  [AP_LATIN]: ['Unit'],
  [AP_CALCULUS_AB]: ['Unit and Topic'],
  [AP_CALCULUS_BC]: ['Unit and Topic'],
  [AP_MUSIC_THEORY]: ['Unit and Topic'],
  [AP_PHYSICS_C_MECHANIC]: ['Unit and Topic'],
  [AP_PHYSCOLOGY]: ['Unit and Topic'],
  [AP_SPANISH_LIT]: ['Unit'],
  [AP_STATISTIC]: ['Unit and Topic'],
  [AP_WORLD_HISTORY]: ['Unit and Topic'],
  [AP_PHYSICS_C_EM]: ['Unit and Topic'],
  [AP_PHYSICS_1]: ['Unit and Topic'],
  [AP_PHYSICS_2]: ['Unit and Topic'],
};

import React from 'react';

const TYPES = {
  SET_USE_CHAMELEON: 'set-use-chameleon',
};

const { SET_USE_CHAMELEON } = TYPES;

const reducer = (state, action) => {
  const { type, payload } = action;
  const { useChameleonApp } = payload || {};

  switch (type) {
    case SET_USE_CHAMELEON:
      return {
        ...state,
        useChameleonApp,
      };
    default:
      throw Error('action type is not supported');
  }
};
const initialValue = {
  useChameleonApp: null,
};

const Context = React.createContext([]);

export default Context;
export { TYPES, initialValue, reducer };
